import React from "react"
import { Row, Col } from "react-flexbox-grid"
import { graphql } from "gatsby"
import "./index.scss"
import Hero from "../../../components/Hero"
import ContentBlock from "../../../components/ContentBlock"
import HelmetWrapper from "../../../components/HelmetWrapper"
import CalloutBlock from "../../../components/CalloutBlock"
import Layout from "../../../components/Layout"

import grantCircle3 from "../../../assets/images/sm_circle_GrantProgram3.png"
import headerHero from "../../../assets/images/patient_advocacy.png"
import arrow from "../../../assets/images/chevron-circle-right-solid.svg"
import { GrantPrograms } from "../../../components/GrantPrograms"

const pageTitle = "External Funding | Harmony Biosciences"
const pageDescription =
  "Learn about our support of patient advocacy or charitable organizations related to therapeutic areas of interest."
const pageKeywords = ""
const smallScreenSize = () => {
  const _screenSize = window ? window.screen.width : 0
  return _screenSize <= 768 ? 66 : 155
}

class grantPrograms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offsetSize: 0,
    }
  }
  componentDidMount() {
    this.setState({
      offsetSize: smallScreenSize(),
    })
  }
  render() {
    const pageContent = this.props.data.contentfulGrantProgram
    return (
      <Layout jobCode="US-HAR-2200051/Jul 2022">
        <div id="patient-advocacy-grants">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
          />
          {pageContent && <GrantPrograms pageContent={pageContent} />}
        </div>
      </Layout>
    )
  }
}

export default grantPrograms

export const pageQuery = graphql`
  query {
    contentfulGrantProgram(slug: { eq: "patient-advocacy-grants" }) {
      id
      slug
      pageTitle
      heroImage {
        gatsbyImageData
      }
      container {
        backgroundColor
        className
        containerId
        title {
          title
        }
        body {
          ... on ContentfulParagraph {
            id
            name
            body {
              raw
            }
          }
          ... on ContentfulAccordion {
            title {
              title
            }
            body {
              raw
            }
          }
        }
        thumbnail {
          gatsbyImageData
        }
      }
      footer {
        paragraph {
          body {
            raw
          }
        }
      }
      buttons {
        backgroundColor
        link
        label
      }
    }
  }
`
