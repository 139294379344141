import React, { Component } from "react"
import { Row, Col } from "react-flexbox-grid"
import Hero from "../Hero"
import ContentBlock from "../ContentBlock"
import Accordion from "../Accordion"
import CalloutBlock from "../CalloutBlock"
import arrow from "../../assets/images/chevron-circle-right-solid.svg"

import "./index.scss"

export const GrantPrograms = props => {
  const { pageContent } = props
  console.log("YYY", pageContent)
  const renderHeroImg = () => {
    const heroImageUrl =
      pageContent.heroImage?.gatsbyImageData?.images?.fallback?.src
    return (
      <Hero bg={heroImageUrl} col={pageContent.titleColumns}>
        <h1>{pageContent.pageTitle}</h1>
      </Hero>
    )
  }
  const renderAccordion = (title, body) => {
    const accordionBody = body?.content?.[0]?.["content"]?.[0].value
    return (
      <Accordion title={title} injectHTML={true}>
        {accordionBody}
      </Accordion>
    )
  }
  const renderParagraph = body => {
    const paraBody = body?.content?.[0]?.["content"]?.[0].value
    console.log("SSS", paraBody)
    return <div dangerouslySetInnerHTML={{ __html: paraBody }} />
    return paraBody
  }

  const renderContainer = () => {
    const containers = pageContent.container
    return (
      containers &&
      containers?.map(container => {
        const includesThumbnail = container?.thumbnail !== null
        const title = container?.title?.title
        const includesTitle = typeof title !== "undefined"
        const thumbnailImg =
          container?.thumbnail?.gatsbyImageData?.images?.fallback?.src

        const renderThumbnailSection = () => {
          return (
            <Col xs={12} md={2}>
              <Row center="xs">
                <Col>
                  <img src={thumbnailImg} className="" />
                </Col>
              </Row>
            </Col>
          )
        }

        const renderBody = () => {
          return container?.body?.map(body => {
            const isAccordion = typeof body?.title !== "undefined"
            return isAccordion ? (
              <>
                {renderAccordion(
                  body?.title?.title,
                  JSON.parse(body?.body?.raw)
                )}
              </>
            ) : (
              <>{renderParagraph(JSON.parse(body?.body?.raw))}</>
            )
          })
        }
        return (
          <ContentBlock
            color={container.backgroundColor}
            id={container.containerId}
            className={container.className}
          >
            <Row>
              {includesThumbnail && renderThumbnailSection()}
              <Col xs={12} md={includesThumbnail ? 10 : 12}>
                {includesTitle && <h2>{title}</h2>}
                {renderBody()}
              </Col>
            </Row>
          </ContentBlock>
        )
      })
    )
  }

  const renderFooter = () => {
    const footers = pageContent.footer?.paragraph
    const includesFooters = typeof footers !== "undefined"

    const footerContent = footers?.map(footer => {
      const rawBody = JSON.parse(footer?.body?.raw)

      const footerContent = rawBody?.content?.[0]?.["content"]?.[0].value
      return (
        footerContent && (
          <p
            className="footnote-small"
            dangerouslySetInnerHTML={{ __html: footerContent }}
          />
        )
      )
    })

    return (
      includesFooters && (
        <ContentBlock color="white" id="footnotes">
          <Row>
            <Col xs={12} md={12}>
              {footerContent}
            </Col>
          </Row>
        </ContentBlock>
      )
    )
  }

  const renderButtons = () => {
    const buttons = pageContent?.buttons
    const renderButtonSection = () =>
      buttons?.map((button, index) => {
        return (
          <Col xs={12} md={6}>
            <CalloutBlock
              className={`text-only ${button.backgroundColor} ${
                index > 1 ? "gap" : ""
              }`}
              link={`/funding-programs/${button.link}`}
            >
              <h2>{button.label}</h2>
              <img className="callout-arrow" src={arrow} />
            </CalloutBlock>
          </Col>
        )
      })
    return (
      buttons && <Row className={"callout-row"}>{renderButtonSection()}</Row>
    )
  }

  const renderPageContent = () => {
    return (
      pageContent && (
        <>
          {renderHeroImg()}
          {renderContainer()}
          {renderFooter()}
          {renderButtons()}
        </>
      )
    )
  }

  return <>{renderPageContent()}</>
}
